const css = /*css*/ `
    @import url("https://rsms.me/inter/inter-ui.css");
    * {
        box-sizing: border-box;
    }

    html,
    body {
        font-family: "Inter UI", "Avenir Next", Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
            Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
        -webkit-font-feature-settings: "kern" 1;
        -moz-font-feature-settings: "kern" 1;
        -o-font-feature-settings: "kern" 1;
        font-feature-settings: "kern" 1;
        font-kerning: normal;
        z-index: 2147483647;
        font-size: 14px;
        transition: all 0.25s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        position: relative;
        word-break: break-word;
        margin: 0;
    }
    

    .load {
        transition: all 0.25s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        position: absolute;
        background-color: white;
        z-index: 100;
        opacity: 0.9;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .load.inactive {
        opacity: 0;
        pointer-events: none;
    }
    .load.active {
        opacity: 0.9;
        pointer-events: all;
    }
    .desc {
        text-align: center;
        position: relative;
        overflow: hidden;
        font-weight: 600;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        flex-direction: column;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 20px;
    }
    .wrap {
        transition: all 0.25s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        position: fixed;
        opacity: 1;
        color: rgba(0, 0, 0, 0.65);
        right: 50%;
        left: 50%;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
    }
   
    .wrap.inactive {
        bottom: 0;
        right: 0;
        height: 55px;
        width: 55px;
    }
    .wrap.active {
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        max-width: 300px;
        background: white;
    }
    svg {
        cursor: pointer;
    }
    ._logo button {
        transition: all 0.25s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        text-align: center;
        z-index: 2147483647;
        border: none;
        background-color: transparent;
        height: 100%;
        width: 100%;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        background: white;
    }
    ._logo{
       height: 100%; 
       overflow: hidden;
    }
    ._logo button:hover {
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
    ._logo button:focus {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
    ._logo button:active {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }
    nav{
        display: table; 
        width: 100%;
        z-index: 1;
    }
   
    nav h2{
        font-size: 18px;
        font-weight: 500;
        color: #63617c;
        margin: 0;
    }
    section{
        overflow-y: auto; 
        height: calc(100vh - 300px);
    }
    .top{
        border-top: 1px solid rgb(226, 219, 232);
        height: calc(100vh);
    }
    article{
        padding: 10px;
        overflow-y: auto;
        max-height: calc(100% - 50px);
    }
    nav ._c{
        border: 0px; 
        color: black; 
        background-color: transparent;
        height: 50px; 
        width: 40px;
    }
    nav div{
        display: table-cell; 
        vertical-align: middle; 
        width: 100%;
    }
    .hidden{
        position: absolute;
        opacity: 0; 
        z-index: -1;
        pointer-events: none;
        top:0;
        bottom:0;
        left:0;
        right:0;
        overflow: hidden;
    }
    button._a,
    a._a {
        background-color: white;
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 10px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #e8e8e8;
        color: rgb(47, 74, 202);
        text-decoration: none;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
    }

    button._a:hover,
    button._a:focus,
    a._a:hover,
    a._a:focus {
        background-color: #f9f9f9;
        color: rgb(14, 123, 255);
    }

    button {
        cursor: pointer;
    }
    button:hover,
    button:focus {
        opacity: 1;
    }
    button:focus,
    button:active {
        transition: all 0.25s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        outline: 0;
    }
    button._c:focus {
        transform: translateY(1px);
        -webkit-transform: translateY(1px);
    }
    button._c:active {
        transform: translateY(2px);
        -webkit-transform: translateY(2px);
    }
    button._b{
        width: 40px;
        height: 50px;
        vertical-align: top;
        display: table-cell;
        border: 0;
        border-color: transparent;
        background: transparent;
    }
    button._b:focus {
        transform: translateX(-1px);
        -webkit-transform: translateX(-1px);
    }
    button._b:active {
        transform: translateX(-2px);
        -webkit-transform: translateX(-2px);
    }

    .load .loader,
    .load .loader:before,
    .load .loader:after {
        background: rgb(14, 123, 255);
        -webkit-animation: load1 1s infinite ease-in-out;
        animation: load1 1s infinite ease-in-out;
        width: 1em;
        height: 4em;
    }
    .load .loader {
        color: rgb(14, 123, 255);
        opacity: 0.5;
        text-indent: -9999em;
        margin: 88px auto;
        position: relative;
        font-size: 11px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    .load .loader:before,
    .load .loader:after {
        position: absolute;
        top: 0;
        content: "";
    }
    .load .loader:before {
        left: -1.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .load .loader:after {
        left: 1.5em;
    }
    @-webkit-keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: 4em;
        }
        40% {
            box-shadow: 0 -2em;
            height: 5em;
        }
    }
    @keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: 4em;
        }
        40% {
            box-shadow: 0 -2em;
            height: 5em;
        }
    }
    
     @media only screen and (max-width: 250px) {
        nav h2{
            font-size: 16px;            
        }
    }

`;
export default css;
