import { writable } from "svelte/store";

export const active = writable(false);
export const project = writable({});

export const defaultColors = [
  "#FFFFFF",
  "#000000",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
  "#f9c80e",
  "#f86624",
  "#ea3546",
  "#662e9b",
  "#43bccd",
  "#ff7700",
  "#4c4f56",
  "#0693cf",
  "#fffcf2",
  "#f2ebe6",
  "#011627",
  "#f71735",
  "#41ead4",
  "#fdfffc",
  "#ff9f1c",
  "#011627",
  "#ff0022",
  "#41ead4",
  "#fdfffc",
  "#b91372",
  "#272727",
  "#fed766",
  "#009fb7",
  "#696773",
  "#eff1f3",
  "#003049",
  "#d62828",
  "#f77f00",
  "#fcbf49",
  "#eae2b7",
  "#631d76",
  "#9e4770",
  "#fbfbfb",
  "#2e2532",
  "#201a23",
  "#f2f3ae",
  "#edd382",
  "#fc9e4f",
  "#f4442e",
  "#020122",
  "#ffe74c",
  "#ff5964",
  "#38618c",
  "#35a7ff",
  "#d7263d",
  "#f46036",
  "#2e294e",
  "#1b998b",
  "#c5d86d"
];
