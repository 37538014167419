<script>
  import { onMount } from "svelte";
  import { active, defaultColors } from "./stores.js";
  export let toggle;
  export let project;
  export let helpdocId;
  let is_active;
  let open_category = false;
  active.subscribe(value => (is_active = value));

  const imgWrapStyle = `max-height: 300px; max-width: 300px; width: 100%; color: ${defaultColors[
    project.textColor
  ] || project.textColor}; background-color: ${defaultColors[project.bgColor] ||
    project.bgColor}; overflow: hidden; position: relative; margin: 0 auto;`;

  const imgStyle = `max-height: 300px; max-width: 300px; opacity: ${
    project.bgOpacity
  }; ${project.bgImage &&
    "background: url(" +
      project.bgImage.src +
      ") center center / cover;"}  width: 100%; height: 100%; position: absolute; background-position: center center;`;

  let categoryKeys = Object.keys(project.categories || []);
  let categoryCache = {};
  let activeCategory = false;
  let load = false;
  let articles = [];

  let activeArticle;
  onMount(function() {
    window.toggleHelpDoc = toggle;
  });
  function loadCategory(category) {
    if (categoryCache[category.key]) {
      articles = categoryCache[category.key];
      activeCategory = category;
      return;
    }
    const startTime = Date.now();
    load = true;
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open(
      "GET",
      `https://helpdoc-prod.firebaseio.com/articles.json?orderBy=%22category%22&equalTo=%22${category.key}%22`,
      true
    );
    req.onload = function() {
      const json = req.response;

      articles = [];
      Object.keys(json).forEach(key => {
        articles.push({ key, data: json[key] });
      });
      articles.sort((a, b) => {
        return a.data.index - b.data.index;
      });
      articles = articles.filter(c => c.data.active);
      const newCache = Object.assign(categoryCache, {
        [category.key]: articles
      });
      categoryCache = newCache;
      activeCategory = category;
      setTimeout(() => (load = false), startTime - Date.now() > 100 ? 0 : 200);
    };
    req.send(null);
  }

  function toggleRelay() {
    activeCategory = false;
    activeArticle = false;
    toggle();
  }
  function back() {
    if (activeCategory && !activeArticle) {
      activeCategory = false;
      activeArticle = false;
      return;
    }
    if (activeCategory && activeArticle) {
      activeArticle = false;
      return;
    }
  }
</script>

<div id="HelpDoc_">

  {#if !is_active}
    <div class="_logo ns">
      <button aria-label="HelpDoc" name="HelpDoc" on:click={toggleRelay}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          viewBox="0 0 60 60"
          version="1.1">
          <g id="Canvas" fill="none">
            <g id="icon">
              <path
                id="?"
                d="M 4.9178 34.7539C 4.9178 33.0141 5.43655 31.5925 6.47406
                30.4892C 7.55307 29.3435 9.02634 28.7706 10.8939 28.7706C
                12.7614 28.7706 14.2139 29.3435 15.2514 30.4892C 16.3304 31.5925
                16.8699 33.0141 16.8699 34.7539C 16.8699 36.4937 16.3304 37.9365
                15.2514 39.0822C 14.2139 40.1855 12.7614 40.7372 10.8939
                40.7372C 9.02634 40.7372 7.55307 40.1855 6.47406 39.0822C
                5.43655 37.9365 4.9178 36.4937 4.9178 34.7539ZM 6.97207 26.4155C
                6.26656 26.0336 5.60255 25.3546 4.98005 24.3787C 4.35754 23.3602
                4.04629 22.1721 4.04629 20.8141C 4.04629 19.4987 4.37829 18.5227
                5.0423 17.8862C 5.7478 17.2072 6.76456 16.698 8.09258 16.3585L
                12.9481 15.1491C 15.0231 14.5975 16.0607 13.5791 16.0607
                12.0938C 16.0607 11.2876 15.6664 10.5874 14.8779 9.99334C
                14.1309 9.39926 12.8236 9.10221 10.9561 9.10221C 9.25459 9.10221
                7.65682 9.3356 6.16281 9.80238C 4.66879 10.2267 3.46528 10.7359
                2.55227 11.33C 1.84677 10.7784 1.24501 10.1206 0.747007 9.35682C
                0.249002 8.55056 0 7.59579 0 6.49249C 0 5.38919 0.311253 4.43441
                0.933759 3.62816C 1.55626 2.77946 2.40702 2.10051 3.48603
                1.5913C 4.60654 1.03965 5.91381 0.63652 7.40782 0.381914C
                8.94334 0.127305 10.6241 0 12.4501 0C 17.3472 0 21.0407 1.12452
                23.5307 3.37355C 26.0622 5.58015 27.328 8.48691 27.328 12.0938C
                27.328 18.8834 22.6385 23.2329 13.2594 25.1425L 6.97207 26.4155Z"
                transform="translate(16.5641 9.66431)"
                fill="rgb(47, 128, 237)" />
            </g>
          </g>
        </svg>
      </button>
    </div>
  {/if}

  <div class={is_active ? 'wrap active' : 'hidden'}>

    <nav style={!activeCategory && !activeArticle ? 'position: absolute;' : ''}>
      {#if load || !activeCategory}
        <div />
      {:else}
        <button class="_b" aria-label="Back" on:click={back}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 306 306"
            xmlSpace="preserve"
            style="enable-background:new 0 0 306 306; width: 20px; margin-right:
            10px;">
            <g id="chevron-left">
              <polygon
                points="247.35,35.7 211.65,0 58.65,153 211.65,306 247.35,270.3
                130.05,153" />
            </g>
          </svg>
        </button>
        <div>
          {#if activeCategory && !activeArticle}
            <h2>{activeCategory.data.name}</h2>
          {:else}
            <h2>{activeArticle.data.name}</h2>
          {/if}
        </div>
      {/if}
      <div>
        <button on:click={toggleRelay} class="_c" aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 96 96"
            enable-background="new 0 0 96 96"
            xmlSpace="preserve"
            width="20px"
            height="20px">
            <polygon
              fill={!activeCategory && !activeArticle ? defaultColors[project.textColor] || project.textColor : 'black'}
              points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96
              96,82 62,48 " />
          </svg>
        </button>
      </div>
    </nav>

    {#if !is_active || (!activeCategory && !activeArticle)}
      <div class="img" style={imgWrapStyle}>
        <div style={imgStyle} />
        <div
          class="desc"
          style="max-height: 300px; max-width: 300px; width: 100%; height: 100%;
          justify-content: center;">
          {@html project.description.replace(/\n/g, '</br>')}
        </div>
      </div>
      <section>
        {#each categoryKeys as category}
          <button
            class="_a"
            aria-label={project.categories[category].name}
            on:click={e => loadCategory({
                key: category,
                data: project.categories[category]
              })}>
            {project.categories[category].name}
          </button>
        {/each}
      </section>
    {/if}

    {#if !load && activeCategory && !activeArticle}
      <section class="top">
        {#each articles as article}
          {#if article.data.type === 'link'}
            <a
              class="_a"
              target="_parent"
              aria-label={article.data.name}
              key={article.key}
              href={article.data.content}>
              {article.data.name}
            </a>
          {:else}
            <button
              class="_a"
              aria-label={article.data.name}
              key={article.key}
              on:click={_ => (activeArticle = article)}>
              {article.data.name}
            </button>
          {/if}
        {/each}
      </section>
    {/if}
    {#if !load && activeCategory && activeArticle}
      <article class="top">
        {@html activeArticle.data.content.replace('target="_self"', 'target="_parent"')}
      </article>
    {/if}

  </div>

</div>

<div class={load ? 'load active' : 'load inactive'}>
  {#if load}
    <div class="loader" />
  {/if}
</div>
