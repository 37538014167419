import App from "./App.svelte";
import outside from "./outside.js";
import inside from "./inside.js";

const srcEle = document.querySelector("script[data-helpdoc]"),
  helpdocId = srcEle.attributes["data-helpdoc"].value,
  openTo = srcEle.attributes["data-category"]
    ? srcEle.attributes["data-category"].value
    : null,
  position = srcEle.attributes["data-position"]
    ? srcEle.attributes["data-position"].value
    : "",
  hideBack = srcEle.attributes["data-hide-back"]
    ? srcEle.attributes["data-hide-back"].value
    : false;

const style = document.createElement("style");
style.type = "text/css";
style.appendChild(
  document.createTextNode(outside.replace(/HelpDoc_/g, helpdocId))
);
document.body.appendChild(style);

const div = document.createElement("div");
div.id = helpdocId;
div.className = position;
document.body.appendChild(div);

const req = new XMLHttpRequest();
req.responseType = "json";
req.open(
  "GET",
  "https://helpdoc-prod.firebaseio.com/projects/" + helpdocId + ".json",
  true
);
req.onload = function() {
  const json = req.response;
  new App({
    target: div,
    props: {
      name: "Jordy",
      inside,
      helpdocId,
      project: json,
      openTo,
      position,
      hideBack
    }
  });
};
req.send(null);
