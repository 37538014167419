<script>
  export let inside;

  let frame;
  let win;
  let doc;
  let head;
  let body;
  let content;
  let component;
  let props;
  $: {
    ({ component, ...props } = $$props);
    if (content && content.set) {
      console.log("$: settings props", props);
      content.set(props);
    }
  }

  $: if (frame) {
    frame.addEventListener("load", loadHandler);
    if (
      frame.contentDocument.readyState === "complete" &&
      frame.contentDocument.defaultView
    ) {
      loadHandler();
    } else {
      frame.addEventListener("load", loadHandler);
    }
  }

  function loadHandler(e) {
    win = frame.contentWindow;
    doc = frame.contentDocument;
    head = doc.head;
    body = doc.body;
    const base = document.createElement("base");
    base.setAttribute("target", "_parent");
    head.appendChild(base);
    doc.insertBefore(document.doctype.cloneNode(true), doc.documentElement);
    const style = doc.createElement("style");
    style.type = "text/css";
    style.appendChild(document.createTextNode(inside));
    head.appendChild(style);
    // Array.from(
    //   document.querySelectorAll('style, link[rel="stylesheet"]')
    // ).forEach(node => head.appendChild(node.cloneNode(true)));
    if (component) {
      content = new component({ target: body, props });
    }
  }
  const style = "border: none; width: 100%;";
</script>

<iframe
  sandbox="allow-popups-to-escape-sandbox allow-scripts allow-popups allow-forms
  allow-pointer-lock allow-top-navigation allow-modals allow-same-origin"
  {style}
  bind:this={frame}
  title="HelpDoc" />
