const css = /*css*/ `
  #HelpDoc_ div:first-child {
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06),
      0 2px 32px rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.25s cubic-bezier(0.51, 0.92, 0.24, 1.15);
    position: fixed;
    border-radius: 3px;
    z-index: 2147483647;
    max-width: 300px;
  }
  #HelpDoc_ div:first-child.inactive {
    bottom: 10px;
    max-height: 55px;
    width: 55px;
    height: 100%;
    background: white;
  }
  #HelpDoc_ div:first-child.inactive {
    right: 10px;
  }
  #HelpDoc_.bottomLeft div:first-child.inactive {
    left: 10px;
  }
  #HelpDoc_.bottomCenter div:first-child.inactive {
    right: 50%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  #HelpDoc_ div:first-child.active {
    bottom: 10px;
    height: 100%;
    max-height: 500px;
    max-width: 300px;
  }
  #HelpDoc_ div:first-child.active {
    right: 10px;
  }
  #HelpDoc_.bottomLeft div:first-child.active {
    left: 10px;
  }
  #HelpDoc_.bottomCenter div:first-child.active {
    width: 100%;
    right: 50%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  #HelpDoc_ div:first-child.loading {
    opacity: 0;
    transform: translate(100%, 100%);
  }
  #HelpDoc_ iframe {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 0;
    outline: 0;
    border-radius: 3px;
  }
  @media only screen and (max-width: 400px) {
    #HelpDoc_ div:first-child.active {
      bottom: 0;
      right: 0;
      border-radius: 0;
      border-right: 0;
      border-bottom: 0;
    }
    #HelpDoc_ iframe {
      border-radius: 0;
    }
  }
`;
export default css;
