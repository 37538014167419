<script>
  import Frame from "./Frame.svelte";
  import Doc from "./Doc.svelte";
  import { onMount } from "svelte";
  import { active } from "./stores.js";

  let is_active;
  active.subscribe(value => (is_active = value));

  export let inside;
  export let helpdocId;
  export let project;

  function toggle() {
    active.set(!is_active);
  }
</script>

{#if project !== null}
  <div on:click={toggle} class={is_active ? 'active' : 'inactive'}>
    <Frame component={Doc} {inside} {toggle} {project} {helpdocId} />
  </div>
{/if}
